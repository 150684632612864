.homepage {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 8rem 1fr 5rem;
	grid-template-areas:
		' top-navbar '
		' main-content '
		' footer  ';
	margin: auto;
	height: 100vh;
	width: 100%;
	max-width: 170rem;
}

.homepage-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4rem;
	align-items: center;
	grid-area: main-content;
}

.homepage-footer {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-area: footer;
}

.feature-section {
	overflow: auto;
	max-width: 85vw;
	width: 100%;
}

.features-row {
	max-width: 100%;
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	grid-template-rows: 1fr;
	gap: 2rem;
	overflow: auto;
}

.features-row > * {
	margin: 1rem;
	cursor: pointer;
}

.features-row > *:hover {
	transform: translateY(-0.1rem);
}

.features-row .card {
	min-width: 30rem;
}

.card-overlay {
	transition: all 0.2s ease-in-out;
}

.card-overlay-holder:hover .card-img {
	filter: blur(5px);
}

.card-overlay-holder:hover .card-overlay {
	opacity: 1;
	background-color: var(--colour-dark-with-opacity-two);
}

/* Category section */
.category-section {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin: 1rem;
}

.category-card {
	color: var(--both-theme-white);
	max-width: 30rem;
	width: 100%;
	margin: 2rem;
	background-color: var(--colour-secondary);
}

.category-title {
	cursor: pointer;
	font-size: 2.5rem;
	font-weight: bold;
	background-color: var(--colour-dark-with-opacity-two);
	height: 100%;
	width: 100%;
	position: absolute;
	display: grid;
	place-content: center;
	text-transform: uppercase;
	transition: all 0.1s ease-in-out;
}

.category-card:hover .category-title {
	transform: scale(1.5);
	transition: all 0.1s ease-in-out;
}
@media only screen and (min-width: 1999px) {
	.feature-section {
		max-width: 170rem;
	}
}
