.wishlist-homepage {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 8rem 1fr 0.5fr 6rem;
	grid-template-areas:
		' top-navbar '
		' main-content '
		'suggestion-section'
		' footer  ';
	margin: auto;
	height: 100vh;
	width: 100%;
	max-width: 80vw;
}

.wishlist-page > .card .fav-holder {
	top: 1rem;
	right: 0rem;
}

.wishlist-page > .card:hover .fav-holder {
	top: 6rem;
	right: -6rem;
}

.wishlist-page > .card .fav-holder svg * {
	fill: var(--colour-error);
	opacity: 1;
}

.remove-wishlist {
	position: absolute;
	width: 100%;
	cursor: pointer;
	padding: 2rem;
	background-color: var(--colour-error);
	color: var(--colour-text-white-two);
	box-shadow: 0px 0px 10px 0px var(--colour-black-with-opacity-two);
	top: -10rem;
	left: 0;
	border-radius: 0.5rem;
	transition: all 0.3s ease-in-out;
}

.wishlist-page > .card:hover .remove-wishlist {
	top: 0;
}

.removing-wishlist {
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: pointer;
	padding: 2rem;
	display: grid;
	place-items: center;
	background-color: var(--colour-error);
	color: var(--colour-text-white-two);
	box-shadow: 0px 0px 10px 0px var(--colour-black-with-opacity-two);
	top: 0;
	left: 0;
	border-radius: 0.5rem;
	animation-duration: 300ms;
	animation-name: remove;
}

@keyframes remove {
	from {
		height: 10%;
	}
	to {
		height: 100%;
	}
}
