.order-homepage {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	grid-template-rows: 8rem 1fr 5rem;
	grid-template-areas:
		'top-navbar'
		'main-content'
		'footer';
	margin: auto;
	min-height: 100vh;
	width: 100%;
	max-width: 80vw;
}

.single-order-container {
	margin: 1rem 0;
}

.product-page-summary-tag {
	display: inline-flex;
	width: 90%;
	cursor: pointer;
}

.product-page-summary-tag > span:last-child {
	margin-left: auto;
}

.order-page-img {
	height: 8rem;
	width: 8rem;
	border-radius: 50%;
	object-fit: cover;
}

.order-page-single-product {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	padding: 1rem;
	border-bottom: 1px solid #e6e6e6;
	cursor: pointer;
}

.order-page-single-product:last-child {
	border-bottom: none;
}

.order-page-single-product .product-detail {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}
